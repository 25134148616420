<script>
import { Line } from 'vue-chartjs'
import chartAnnotation from 'chartjs-plugin-annotation';

export default {
  extends: Line,
  props:['chartdata','options'],
  async mounted () {
    this.renderChart(this.chartdata, this.options);
  }
};
</script>