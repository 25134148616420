import { render, staticRenderFns } from "./InboundStatusesUndelivered.vue?vue&type=template&id=203a91ba&scoped=true"
import script from "./InboundStatusesUndelivered.vue?vue&type=script&lang=js"
export * from "./InboundStatusesUndelivered.vue?vue&type=script&lang=js"
import style0 from "./InboundStatusesUndelivered.vue?vue&type=style&index=0&id=203a91ba&prod&scoped=true&lang=css"
import style1 from "./InboundStatusesUndelivered.vue?vue&type=style&index=1&id=203a91ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203a91ba",
  null
  
)

export default component.exports