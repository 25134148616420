<template>
  <div class="pop_container">
    <div class="title_area">{{ undeliveredAction }}<a class="close-btn"@click="closeModal">X</a></div>
    <div class="body_area">
      <div class="form_section_generate_slip">
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <table>          
            <tr>
              <td colspan="3">
                <ValidationProvider name="comment" rules="required|max:100" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Instructions
                    <span>*</span>
                  </label>
                  <textarea placeholder="Instructions here Character Limit 100" v-model="statuscourier.note" :maxlength="max1" :class="errors[0] ? 'error-border':''"></textarea>
                </div>
                </ValidationProvider>
              </td>
            </tr>
            
            <tr>
              <td colspan="3">
                <ValidationProvider name="comment" rules="" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Email
                    <span>*</span>
                  </label>
                  <textarea placeholder="" v-model="statuscourier.note" :maxlength="max1" :class="errors[0] ? 'error-border':''"></textarea>
                </div>
                </ValidationProvider>
              </td>
            </tr>

            <tr>
              <td>
                <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Upload Image
                    </label>
                    <label class="user_profile_upload ">
                      <span>Choose a File</span>
                     
                      <input type="file" ref="logo" accept="image/*"
                        :class="errors[0] ? 'warning-border' : ''" />
                   
                    </label>
                  </div>
                </ValidationProvider>
              </td>


              <td>
                <ValidationProvider name="terms" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Upload Document
                      <!-- <span>*</span> -->
                    </label>
                    <label class="user_profile_upload">
                      <span>Choose a File</span>
                      <span style="float: right; color: #767272;font-size: 14px;">PDF Format Recommended</span>
                      <input type="file" ref="sla"  accept=".pdf"
                        :class="errors[0] ? 'warning-border' : ''" />
                     
                    </label>
                  </div>
                </ValidationProvider>
              </td>
            </tr>
          </table>
          
        </ValidationObserver>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="submit">Update</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  name: "popUpdateStatus",
  components: {
    flatPickr
  },
  data() {
    return {
      max:30,
      max1:100,
      date: this.moment(new Date()).format("YYYY-MM-DD"),
      time: this.moment(new Date()).format("HH:mm:ss"),
      modalStatus: false,
      enableTime: true,
      configTime: {
        enableTime: true,
        noCalendar: true,         
        dateFormat: "H:i",
        time_24hr: true
      },
      configDate: {
       format: "d-m-Y",
        altFormat: "m-d-Y",
        altInput: true,
        altInputClass:""
      },
      statuscourier:{
        status:"",
        date:"",
        time:"",
        sender_or_receiver:"",
        note:""
      }
    };
  },

 methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    submit(){
      this.toast.success("Success !");
      this.$emit("closeModal");
      // if(this.type != 'all'){
      //   if(confirm("Are you sure you want to update this status?")){
      //     this.updateStatus();
      //   }
      // }else{
      //   this.updateStatus();
      // }
    },
    updateStatus(){
        this.$refs.observer.validate();
        this.axios.post("/api/courier/"+this.airway_bill+"/status",this.statuscourier)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit("closeModal");
        })
        .catch(error =>{
          console.log(error);
        });
    }
  },
  props: {
    booking_id:String,
    airway_bill:String,
    undeliveredAction:String,
    type:{
      type:String,
      default:"all"
    }
  }
};
</script>
<style lang="scss">
.pop_container {
  height: auto;
}
.form_section_generate_slip1 input[type="text"] {
  width: 100%;
  background: none;
  color: #000;
  height: 40px;
  width: auto;
  padding: 2px 10px;
  margin-right: 5px;
}
.border-danger{
  border: 1px solid #ff3333 !important;
}

.border-danger input{
  border:none !important;
}

.error-border {
  border: 1px solid #ff3333 !important;
}
.close-btn {
  float: right;
  margin-right: 16px;
}

.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}

.popup_sub_container>section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}

.preferred_hide {
  display: none;
}

.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
  border: 0;
}

.jobs_table table tr td {
  padding: 0;
  border: 0;
}

.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 30px;
}

.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}
</style>